@charset "utf-8";

// Variables
$base-font-family: "Albert Sans", serif;

$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$link-color:       #428CAE;
$background-color: #EAF3F5;
$brand-color:      #35499E;

$red-color:        #AA504C;
$grey-color:       #828282;
$blue-color-dark:  #35499E;
$blue-color-light: #495BA8;
$green-color:      #35799E;
$text-color:       darken($grey-color, 50%);
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    1800px;

$on-palm:          600px;
$on-laptop:        800px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin media-query-min($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "mixins",
        "fonts",
        "base",
        "layout",
        "syntax-highlighting",
        "brands",
        "location",
        "services",
        "products",
        "masonry"
;
