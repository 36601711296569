.text-normal {
  font-family: $base-font-family;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.text-medium {
  font-family: $base-font-family;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.text-semibold {
  font-family: $base-font-family;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.text-bold {
  font-family: "Albert Sans", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
