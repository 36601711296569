.services {
  h1 {
    margin-top: 0;
    padding-top: 15px;
  }
  .thumbnail {
    margin: 00px 0px 20px 25px;
    img {
      width: 128px;
      height: 128px;
    }
  }
  .iframe-wrapper {
    margin-bottom: -7px;

    iframe {
      @include box-shadow(0, -2px, 2px, #ddd, 'inset');
    }
  }
}
