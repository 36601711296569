// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// box shadow
@mixin box-shadow($left, $top, $blur, $color, $inset:"") {
      -webkit-box-shadow:$left $top $blur $color #{$inset};
      -moz-box-shadow:$left $top $blur $color #{$inset};
      box-shadow:$left $top $blur $color #{$inset};
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}

//styles

@mixin palm-button($padding-v, $padding-h, $bg-color: $grey-color-light) {
  @include media-query($on-palm) {
    &:focus,&:hover {
      text-decoration: none;
    }
    @include box-shadow(1px, 2px, 2px, $grey-color);
    background: $bg-color;
    padding: $padding-v $padding-h;
    border-radius: 3px;
    color: $text-color;
  }
}
