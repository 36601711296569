/**
 * Colors
 */
 .blue-dark-bg, .blue-light-bg, .green-bg, .red-bg {
   color: #fff;

   a {
     color: #fff;
   }
 }
 .white-bg {
   background-color: #fff;
 }
 .blue-dark-bg {
   background-color: $blue-color-dark;
 }
 .blue-light-bg {
   background-color: $blue-color-light;
 }
 .green-bg {
   background-color: $green-color;
   p {
     color: #BDD3DF;
   }
 }
 .red-bg {
   background-color: $red-color;
   p {
     color: #DFBEBD;
   }
 }

/**
 * Site header
 */

.site-header {
  min-height: 56px;
  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-header-contact {
  color: #333;
  font-size: 1.1em;
  padding: 7px 5px;
  color: #666;

  @include media-query($on-palm) {
    padding-right: 10px;
    text-align: center;
  }
}

.site-title {
  font-size: 26px;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  width: 200px;
  max-width: 100%;

  &,
  &:visited {
    color: $grey-color-dark;
  }

  @include media-query($on-palm) {
    line-height: 70px;
    img {
      height: 50px;
    }
  }
}

.site-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:10px;

  @include media-query-min($on-laptop) {
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }


  .site-nav-item {
    color: $brand-color;
    display: inline-block;
    padding: 20px;

    @include media-query($on-palm) {
      display: none;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  table {
    td {
      border-top-width: 0 !important;
    }
  }
}

.footer-heading {
  font-size: 18px;
  margin-bottom: 5px;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  @extend %clearfix;
  @include media-query($on-palm) {
    margin-left: -$spacing-unit / 2;
  }
}

.footer-col {
  padding: 0;
  >* {
    padding: 30px 30px 0 30px;
    @include media-query-min($on-palm) {
      min-height: 305px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    a {
      color: #fff;
    }
  }
}

/**
 * Page content
 */

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}
/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;
  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;
    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;
    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;
    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}

img.work {
  max-width: 100%;
  border: 1px solid #ddd;
  padding: 1px;
}


/**
* Index
*/

#splash-image {
  width: 100%;
  min-height: 160px;
  background-image: url('../images/bg.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: $spacing-unit;
  @include media-query-min($on-laptop) {
    min-height: 300px;
  }
}
