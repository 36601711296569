.grid {
  display: none;
  overflow: hidden;
}
.grid-loading {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  img {
    height: 100%;
    width: 100%;
  }
}
.grid-item {
  border: 1px solid hsla(0, 0%, 0%, 0.5);
  cursor: pointer;
}
.show-after-grid {
  display: none;
}
.grid-item, .grid-sizer {
    @include media-query-min($on-laptop) {
      max-width: 25%;
      max-height: 400px;
    }

    @include media-query-min($on-palm) {
      max-width: 33.3%;
      max-height: 400px;
    }

    @include media-query($on-palm) {
      max-width: 50%;
      max-height: 300px;
    }
}

.grid-item--focus {
  z-index: 2;
  position: fixed !important;
  top: 0% !important;
  left: 0% !important;
  right: 0% !important;
  bottom: 0% !important;
  max-height: 100%;
  max-width: 100%;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  text-align: center;

  img {
    @include media-query-min($on-palm) {
      position: relative;
      top: 120px;
    }

    @include media-query($on-palm) {
      position: absolute;
      top: 70px;
      margin-left: -50%;
    }
  }
}

.grid-item--unfocus {
  opacity: 0.3;
}
